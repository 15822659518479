import React from "react";
import "../styles/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "../images/logo.png";

function Footer() {
  return (
    <div className="footer" id="footer">
      <div className="str">
        <div className="header">
          <h3>Trulli Bella Vite</h3>
          <p>
            Viale Cristoforo colombo 146, <b>70017 Putignano</b>
          </p>
        </div>
        <span>
          <FontAwesomeIcon icon={faPhone} />{" "}
          <a href="tel:+39 334 353 8272"> +39 334 353 8272</a>
        </span>
        <div className="icons"></div>
      </div>

      <img src={logo} className="logo" alt="" />

      <div className="str">
        <div className="header">
          <h3>Trulli Del Boschetto</h3>
          <p>
            Strada Comunale Boschetto 33, <b>70017 Putignano</b>
          </p>
        </div>
        <span>
          <FontAwesomeIcon icon={faPhone} />{" "}
          <a href="tel:+39 348 392 5541">+39 348 392 5541</a>
        </span>
        <div className="icons"></div>
      </div>
    </div>
  );
}

export default Footer;
