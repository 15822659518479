import { AppBar, Container, Grid, Stack, Toolbar } from "@mui/material";
import React, { useState } from "react";
import "../styles/homepage.scss";
import { Button } from "@mui/joy";
import Struttura from "../components/struttura";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import MenuIcon from '@mui/icons-material/Menu';
import campi from "../images/tdb orizzontale/Trullo/22.jpg";
import spazi from "../images/tdb orizzontale/Trullo/23.jpg";
import camera from "../images/tdb orizzontale/Trulletto/3.jpg";
import ConsigliSlider from "../components/consigli-slider";
import Footer from "../components/footer";
import { Link } from "react-router-dom";

export default function HomePage() {
  const [navChanged, setNavChanged] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [showNav, setShowNav] = useState("100%");
  const [showP, setShowP] = useState("none");
  const [cardHovered, setcHovered] = useState(0);
  const [photoselected, setPhoto] = useState(1);

  const foto = [campi, spazi, camera];

  //scroll to top when loaded
  window.onload = () => {
    window.scrollTo(0, 0);
  };

  function setNavBack() {
    if (navChanged) return "#364731";
    else
      return "linear-gradient(180deg,rgba(0, 0, 0, 0.628) 0%,rgba(0, 0, 0, 0) 100%)";
  }

  function detectScroll() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) setNavChanged(true);
      else if (window.scrollY === 0) setNavChanged(false);
    });
  }
  //scroll to an html element function at the start
  function goTo(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "start" });

    setTimeout(() => {
      if (menuOpened) setMenuOpened(false);
    }, 100);

    // destroy timeout
    clearTimeout();
  }

  function scrolltoSlide(number) {
    //get window height
    const height = window.innerHeight;
    //scroll to a certain heigth of page
    window.scrollTo({ top: height * number, behavior: "smooth" });
  }

  detectScroll();

  return (
    <div className="homePage">
      <Container maxWidth="" className="vetrina">
        <AppBar
          position="fixed"
          color="transparent"
          style={{
            background: setNavBack(),
            transition: "0.3s linear",
            opacity: showNav,
          }}
        >
          <Toolbar className="toolbar">
            <Grid container>
              <Grid item xs={4}>
                <div className="sideSpans">
                  <span
                    onClick={() => {
                      goTo("tbv");
                    }}
                  >
                    Trulli Bella Vite
                  </span>
                  <span
                    onClick={() => {
                      goTo("tdb");
                    }}
                  >
                    Trulli del Boschetto
                  </span>
                </div>
              </Grid>
              <Grid item xs={4}>
                <span
                  className="logoSpan"
                  onClick={() => {
                    scrolltoSlide(0);
                  }}
                >
                  <h1>Trulli Delia</h1>
                </span>
              </Grid>
              <Grid item xs={4}>
                <div className="sideSpans">
                  <span
                    onClick={() => {
                      goTo("slider");
                    }}
                  >
                    Luoghi da visitare
                  </span>
                  <span
                    id="prenota"
                    onClick={() => {
                      goTo("footer");
                    }}
                  >
                    Prenota Ora
                  </span>
                </div>
              </Grid>
            </Grid>
          </Toolbar>

          <Toolbar className="mobile-toolbar">
            <MenuIcon style={{ color: "white", fontSize: "2rem" }} onClick={() => { setMenuOpened(!menuOpened) }} />
            <span
              className="logoSpan"
              onClick={() => {
                scrolltoSlide(0);
              }}
            >
              <h1>Trulli Delia</h1>
            </span>
          </Toolbar>

          {menuOpened && (
            <div className="mobile-menu">
              <span onClick={() => {
                goTo("tbv");
              }}>
                Trulli Bella Vite
              </span>

              <span onClick={() => {
                goTo("tdb");
              }}>
                Trulli Del Boschetto
              </span>
              <span
                onClick={() => {
                  goTo("slider");
                }}
              >
                Luoghi da visitare
              </span>
              <span
                id="prenota"
                onClick={() => {
                  goTo("footer");
                }}
              >
                Prenota Ora
              </span>
            </div>
          )}
        </AppBar>
      </Container>

      <Container className="pageContainer" style={{ padding: "0px 50px" }} maxWidth="">
        <Stack>
          <span className="subtitleSection">
            <h2>Delle Strutture Eccezionali</h2>
            <p>
              Pronti a vivere l’esperienza autentica di alloggiare in un tipico
              <b> trullo Pugliese</b>. Nelle nostre strutture sarete a pochi
              passi dalle numerose attrazioni della zona, tra cui i pittoreschi
              centri storici di <b>Alberobello e Locorotondo</b>, le spiagge
              bandiera blu di Monopoli e Polignano a mare, il famosissimo zoo
              safari di Fasano e infine le grotte di Castellana.
            </p>
            <span style={{ fontSize: "1.3rem" }} id="tbv">
              <b>
                <i>Benvenuti in Puglia</i>
              </b>
            </span>
          </span>

          <Struttura title="Trulli Bella Vite" struttura="tbv" />
          <div className="tbvCards">
            <div id="card1" className="card">
              <div
                className="descrizione"
                onMouseOver={() => {
                  setcHovered(1);
                  setShowP("block");
                }}
                onMouseLeave={() => {
                  setcHovered(1);
                  setShowP("none");
                }}
              >
                <h1>3 Camere Confortevoli</h1>
                <p style={{ display: cardHovered === 1 ? showP : "none" }}>
                  In questa raffinata struttura, tre camere confortevoli si
                  aprono all'accoglienza. Ogni stanza, arredata con cura e
                  attenzione ai dettagli, offre un rifugio accogliente e
                  rilassante per un soggiorno indimenticabile
                </p>
              </div>
            </div>

            <div id="card2" className="card">
              <div
                className="descrizione"
                onMouseOver={() => {
                  setcHovered(2);
                  setShowP("block");
                }}
                onMouseLeave={() => {
                  setcHovered(2);
                  setShowP("none");
                }}
              >
                <h1>Una piscina rilassante</h1>
                <p style={{ display: cardHovered === 2 ? showP : "none" }}>
                  Immersa in un lussureggiante giardino, la piscina confortevole
                  accoglie con le sue acque cristalline. Un'oasi di relax e
                  piacere, dove ci si abbandona al dolce abbraccio dell'acqua,
                  godendo di un comfort senza pari.
                </p>
              </div>
            </div>
            <div id="card3" className="card">
              <div
                className="descrizione"
                onMouseOver={() => {
                  setcHovered(3);
                  setShowP("block");
                }}
                onMouseLeave={() => {
                  setcHovered(3);
                  setShowP("none");
                }}
              >
                <h1>Gazebo Esterno</h1>
                <p style={{ display: cardHovered === 3 ? showP : "none" }}>
                  All'ombra di un elegante gazebo esterno, un invitante tavolo
                  si adorna di prelibatezze preparate su un moderno barbecue a
                  vostra disposizione. Un luogo incantevole per gustare cene
                  all'aperto, tra profumi succulenti e serate indimenticabili.
                </p>
              </div>
            </div>
          </div>

          <span style={{ width: "100%", textAlign: "center", margin: "5% 0%" }}>
            <Link to={"/tbv"}>
              <Button
                color="neutral"
                size="lg"
                variant="outlined"
                style={{ border: "1px solid", borderRadius: "0px" }}
              >
                Scopri tutto sulla struttura
              </Button>
            </Link>
          </span>

          <div
            className="divisore"
            onMouseOver={() => {
              setShowNav("0%");
            }}
            onMouseLeave={() => {
              setShowNav("100%");
            }}
          >
            <h1>Ci mettiamo il cuore</h1>
            <div className="cards">
              <div className="card">
                <AutoAwesomeOutlinedIcon className="icon" style={{ fontSize: "6rem" }} />
                <h2>Pulizia</h2>
                <p>
                  Le nostre camere vantano una pulizia eccellente, dove{" "}
                  <b>ogni angolo risplende di brillantezza </b>. Garantiamo un
                  ambiente immacolato per il tuo soggiorno.
                </p>
              </div>
              <div className="card" id="centrale">
                <i
                  className="fa-solid fa-leaf icon"
                  style={{ fontSize: "6rem" }}
                ></i>
                <h2>Natura</h2>
                <p>
                  Esplora il verde delle nostre strutture, ti accoglieranno
                  <b> alberi maestosi e fiori variopinti</b>. Questa natura
                  rigogliosa ti farà sentire in contatto con la vita, offrendoti
                  una sensazione di pace e serenità.
                </p>
              </div>
              <div className="card">
                <i
                  className="fa-solid fa-water-ladder icon"
                  style={{ fontSize: "6rem" }}
                ></i>
                <h2>Relax</h2>
                <p>
                  Vieni a goderti il massimo del relax nella nostra struttura.
                  Con ambienti accoglienti e servizi pensati per il tuo
                  benessere, potrai finalmente <b>staccare la spina</b> e
                  dedicarti a te stesso.
                </p>
              </div>
            </div>

            <div className="buttonSection" id="tdb">
              <Button
                variant="outlined"
                size="lg"
                style={{
                  borderRadius: "0px",
                  border: "1px solid #364731",
                  color: "#364731",
                  width: "200px",
                }}
                onClick={() => {
                  goTo("footer");
                }}
              >
                Prenota Ora
              </Button>
            </div>
          </div>

          <Struttura title="Trulli del Boschetto" struttura="tdb" id="tdb" />

          <div className="tdbSection">
            <div className="fotoSlideSection">
              <img src={foto[photoselected]} alt="" />
            </div>
            <div className="features">
              <h1>Goditi un'esperienza unica</h1>
              <h2>Esplora la struttura ricca di elementi suggestivi</h2>

              <div className="elements">
                <span
                  className="element"
                  onMouseEnter={() => {
                    setPhoto(0);
                  }}
                >
                  Campi da calcio
                </span>
                <span
                  className="element"
                  onMouseEnter={() => {
                    setPhoto(1);
                  }}
                >
                  Grandi spazi aperti
                </span>
                <span
                  className="element"
                  onMouseEnter={() => {
                    setPhoto(2);
                  }}
                >
                  Camere Confortevoli
                </span>
              </div>

              <div className="buttonSection">
                <Link to={"/tdb"}>
                  <Button
                    variant="outlined"
                    size="lg"
                    color="neutral"
                    style={{
                      borderRadius: "0px",
                      border: "1px solid #282424",
                    }}
                  >
                    Scopri di più
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <div
            id="slider"
            className="luoghi"
            onMouseOver={() => {
              setShowNav("0%");
            }}
            onMouseLeave={() => {
              setShowNav("100%");
            }}
          >
            <h1> Ti consigliamo di visitare...</h1>
            <div className="lista">
              <ConsigliSlider />
            </div>
          </div>
        </Stack>
      </Container>
      <Footer />
    </div>
  );
}
