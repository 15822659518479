import React, { useEffect, useState } from "react";
import { AppBar, Container, Dialog, Grid, Stack, Toolbar } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import MenuIcon from "@mui/icons-material/Menu";
import Autoplay from "embla-carousel-autoplay";
import { Button } from "@mui/joy";
import cover1 from "../images/tdb orizzontale/Girasole/1.jpg";
import cover2 from "../images/tdb orizzontale/Trullo/2.jpg";
import cover3 from "../images/tdb orizzontale/Trullo/18.jpg";
import barbecue from "../images/tdb orizzontale/Trullo/21.jpg";
import campi from "../images/tdb orizzontale/Trullo/22.jpg";
import parcheggio from "../images/tdb orizzontale/Trullo/23.jpg";
import "../styles/tbv.scss";
import "../styles/tdb.scss";
import Footer from "../components/footer";
import DialogBody from "../components/dialog-content";
import { Link } from "react-router-dom";

const vetrinaImgs = [cover1, cover2, cover3];

export default function TBV(props) {
  const [navChanged, setNavChanged] = useState(false);
  const [currentCover, setCurrentCover] = useState(vetrinaImgs[0]);
  const [menuSelected, setMenuSelected] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [phoneView, setPhoneView] = useState(false);
  const [emblaRef] = useEmblaCarousel({ loop: true, dragFree: true }, [
    Autoplay({ delay: 5000 }),
  ]);

  //scroll to top when rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function detectScroll() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) setNavChanged(true);
      else if (window.scrollY === 0) setNavChanged(false);
    });
  }

  //scroll to an html element function at the start
  function goTo(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  detectScroll();

  useEffect(() => {
    const timeout = setTimeout(() => {
      let indexCurrentCover = vetrinaImgs.indexOf(currentCover);
      if (indexCurrentCover === vetrinaImgs.length - 1)
        setCurrentCover(vetrinaImgs[0]);
      else setCurrentCover(vetrinaImgs[indexCurrentCover + 1]);
    }, 10000);

    return () => clearTimeout(timeout);
  }, [currentCover]);

  // check if phone view
  useEffect(() => {
    if (window.innerWidth < 900) setPhoneView(true);
    else setPhoneView(false);
  }, []);

  return (
    <div className="tbvPage">
      <AppBar
        position="fixed"
        color="transparent"
        style={{
          background:
            "linear-gradient(180deg,rgba(0, 0, 0, 0.628) 0%,rgba(0, 0, 0, 0) 100%)",
          transition: "0.3s linear",
          display: navChanged ? "none" : "flex",
        }}
      >
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={4}>
              <div className="sideSpans">
                <span>
                  <Link
                    to={"/tbv"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Trulli Bella vite
                  </Link>
                </span>
                <span>
                  <Link
                    to={"/tdb"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Trulli del Boschetto
                  </Link>
                </span>
              </div>
            </Grid>
            <Grid item xs={4}>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <span className="logoSpan">
                  <h1>Trulli Delia</h1>
                </span>
              </Link>
            </Grid>
            <Grid item xs={4}>
              <div className="sideSpans">
                <span
                  id="prenota"
                  onClick={() => {
                    goTo("footer");
                  }}
                >
                  Prenota Ora
                </span>
              </div>
            </Grid>
          </Grid>
        </Toolbar>

        <Toolbar className="mobile-toolbar">
          <MenuIcon
            style={{ color: "white", fontSize: "2rem" }}
            onClick={() => {
              setMenuOpened(!menuOpened);
            }}
          />
          <span className="logoSpan">
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <h1>Trulli Delia</h1>
            </Link>
          </span>
        </Toolbar>

        {menuOpened && (
          <div className="mobile-menu">
            <span>
              <Link
                to={"/tbv"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  background: "none",
                }}
              >
                Trulli Bella vite
              </Link>
            </span>

            <span>
              <Link
                to={"/tdb"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  background: "none",
                }}
              >
                Trulli Del Boschetto
              </Link>
            </span>

            <span
              id="prenota"
              onClick={() => {
                goTo("footer");
              }}
            >
              Prenota Ora
            </span>
          </div>
        )}
      </AppBar>

      <div className="miniVetrina">
        <img src={currentCover} />
      </div>

      <Container
        className="pageContainer"
        style={{ padding: "0px 50px", marginTop: "50px" }}
        maxWidth=""
      >
        <Stack spacing={8}>
          <div className="headerSection">
            <h1>Trulli del Boschetto</h1>
            <p>
              Siamo lieti di accogliervi nella nostra struttura immersa nella
              <b> natura</b>, con camere confortevoli e spazi per rilassarsi al
              massimo. La bellezza panoramica dei nostri dintorni vi regalerà
              momenti di <b>pace e tranquillità</b>, mentre il nostro personale
              attento e cortese sarà sempre a vostra disposizione per rendere il
              vostro soggiorno <b>indimenticabile.</b>
            </p>
            <p>
              <b>
                <i>
                  Benvenuti nel vostro rifugio perfetto lontano dallo stress
                  quotidiano!
                </i>
              </b>
            </p>
            <div className="menu  menu-tbv">
              <span
                onClick={() => {
                  setMenuSelected(1);
                }}
                style={{
                  background: menuSelected === 1 ? "rgb(255, 243, 226)" : "",
                  color: menuSelected === 1 ? "#364731" : "",
                }}
              >
                Camere
              </span>
              <span
                onClick={() => {
                  setMenuSelected(2);
                }}
                style={{
                  background: menuSelected === 2 ? "rgb(255, 243, 226)" : "",
                  color: menuSelected === 2 ? "#364731" : "",
                }}
              >
                Aree Comuni
              </span>
            </div>
          </div>

          {(menuSelected === 1 || phoneView) && (
            <div className="camere">
              <Dialog open={dialogOpen !== false} maxWidth="xl">
                {dialogOpen !== false && (
                  <DialogBody
                    dialogOpen={dialogOpen}
                    dialogClose={() => {
                      setDialogOpen(false);
                    }}
                  />
                )}
              </Dialog>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h1>Camere</h1>
                <p>
                  Le nostre camere sono state progettate per offrire il massimo
                  comfort e relax. Sono tutte dotate di bagno privato, aria
                  condizionata, riscaldamento, TV, Wi-Fi gratuito e frigorifero.
                </p>
              </div>

              <div className="camere-list">
                <div className="camera" id="trullotdb">
                  <div className="descrizione">
                    <h1>Trullo</h1>
                    <h2>fino a 4 ospiti</h2>

                    <i
                      className="fa-solid fa-arrow-right"
                      onClick={() => {
                        setDialogOpen(3);
                      }}
                    ></i>
                  </div>
                </div>

                <div className="camera" id="trulletto">
                  <div className="descrizione">
                    <h1>Trulletto</h1>
                    <h2> fino a 2 ospiti</h2>

                    <i
                      className="fa-solid fa-arrow-right"
                      onClick={() => {
                        setDialogOpen(4);
                      }}
                    ></i>
                  </div>
                </div>

                <div className="camera" id="girasole">
                  <div className="descrizione">
                    <h1>Girasole</h1>
                    <h2> (Appartamento) fino a 3 ospiti</h2>

                    <i
                      className="fa-solid fa-arrow-right"
                      onClick={() => {
                        setDialogOpen(5);
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(menuSelected === 2 || phoneView) && (
            <div className="areeComuni ac-tbv">
              <h1>Aree comuni</h1>

              <div className="listaAree camere">
                <div
                  className="camera"
                  style={{ backgroundImage: `url(${barbecue})` }}
                >
                  <div className="descrizione">
                    <h1>Barbecure</h1>
                    <h2>Con un comodo tavolo esterno</h2>
                  </div>
                </div>
                <div
                  className="camera"
                  style={{ backgroundImage: `url(${parcheggio})` }}
                >
                  <div className="descrizione">
                    <h1>Parcheggio gratuito</h1>
                    <h2>Con 3 posti auto</h2>
                  </div>
                </div>
                <div
                  className="camera"
                  style={{ backgroundImage: `url(${campi})` }}
                >
                  <div className="descrizione">
                    <h1>Campi da calcio</h1>
                    <h2>due campi da calcio per divertirsi</h2>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="divider"></div>
        </Stack>
      </Container>
      <Footer />
    </div>
  );
}
