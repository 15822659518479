import React from "react";
import "../styles/homepage.scss";
import tbv from "../images/tbv orizzontale/Trullo/1.jpg";
import tdb from "../images/tdb orizzontale/Trullo/1.jpg";
import { Button } from "@mui/joy";
import { Link } from "react-router-dom";

export default function Struttura(props) {
  return (
    <>
      <div className="struttura">
        <div className="strutture-left-section">
          <h1>{props.title}</h1>
          {props.struttura.toString() === "tbv"
            ? descriptions[0]
            : descriptions[1]}
          <span className="buttonSection">
            <Link to={props.struttura.toString() === "tbv" ? "/tbv" : "/tdb"}>
              <Button color="neutral" size="lg" variant="outlined">
                Visualizza la Struttura
              </Button>
            </Link>
          </span>
        </div>
        <div className="strutture-right-section">
          <img src={props.struttura === "tbv" ? tbv : tdb} alt="" />
        </div>
      </div>
    </>
  );
}

const descriptions = [
  <p>
    Trulli bella vite è situato nelle campagna della cittadina di
    <b> Putignano</b>, punto strategico per visitare i migliori luoghi della
    Puglia. La nostra struttura è composta da 3 soluzioni:{" "}
    <b>Trullo, Pietra Viva, Magnolia</b>, tutte climatizzate e accessoriate con
    cucina e bagno. L’intera struttura può ospitare <b>10 ospiti</b>. Nelle aree
    comuni potrete utilizzare la nostra piscina al sale e il gazebo con il
    barbecue. Inoltre il parcheggio è gratuito.
  </p>,
  <p>
    Trulli del boschetto è situato nelle campagna della cittadina di
    <b> Putignano</b>, punto strategico per visitare i migliori luoghi della
    Puglia. La nostra struttura è composta da 3 soluzioni:
    <b> Trullo, Trulletto, Girasole </b>, tutte climatizzate e accessoriate con
    bagno. L’intera struttura può ospitare <b> 9 ospiti</b>. Nelle arie comuni
    potrete utilizzare il barbecue. Inoltre il parcheggio è gratuito.
  </p>,
];
