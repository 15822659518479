import React, { useEffect, useState } from "react";
import { AppBar, Container, Dialog, Grid, Stack, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { Button } from "@mui/joy";
import cover1 from "../images/tbv orizzontale/Trullo/1.jpg";
import cover2 from "../images/tbv orizzontale/Trullo/2.jpg";
import cover3 from "../images/tbv orizzontale/Trullo/17.jpg";
import gazebo from "../images/tbv orizzontale/Trullo/14.jpg";
import piscina from "../images/tbv orizzontale/orizzontale.jpg";
import giardino from "../images/tbv orizzontale/Trullo/18.jpg";
import "../styles/tbv.scss";
import Footer from "../components/footer";
import DialogBody from "../components/dialog-content";
import { Link } from "react-router-dom";

const vetrinaImgs = [cover1, cover2, cover3];

export default function TBV(props) {
  const [navChanged, setNavChanged] = useState(false);
  const [currentCover, setCurrentCover] = useState(vetrinaImgs[0]);
  const [menuOpened, setMenuOpened] = useState(false);
  const [menuSelected, setMenuSelected] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [phoneView, setPhoneView] = useState(false);
  const [emblaRef] = useEmblaCarousel({ loop: true, dragFree: false }, [
    Autoplay({ delay: 5000 }),
  ]);

  //scroll to top when rendered
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function detectScroll() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) setNavChanged(true);
      else if (window.scrollY === 0) setNavChanged(false);
    });
  }

  //scroll to an html element function at the start
  function goTo(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth", block: "start" });

    setTimeout(() => {
      if (menuOpened) setMenuOpened(false);
    }, 100);

    // destroy timeout
    clearTimeout();
  }

  detectScroll();

  useEffect(() => {
    const timeout = setTimeout(() => {
      let indexCurrentCover = vetrinaImgs.indexOf(currentCover);
      if (indexCurrentCover === vetrinaImgs.length - 1)
        setCurrentCover(vetrinaImgs[0]);
      else setCurrentCover(vetrinaImgs[indexCurrentCover + 1]);
    }, 10000);

    return () => clearTimeout(timeout);
  }, [currentCover]);

  // check if phone view
  useEffect(() => {
    if (window.innerWidth < 900) setPhoneView(true);
    else setPhoneView(false);
  }, []);

  return (
    <div className="tbvPage">
      <AppBar
        position="fixed"
        color="transparent"
        style={{
          background:
            "linear-gradient(180deg,rgba(0, 0, 0, 0.628) 0%,rgba(0, 0, 0, 0) 100%)",
          transition: "0.3s linear",
          display: navChanged ? "none" : "flex",
        }}
      >
        <Toolbar className="toolbar">
          <Grid container>
            <Grid item xs={4}>
              <div className="sideSpans">
                <span>
                  <Link
                    to={"/tbv"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Trulli Bella vite
                  </Link>
                </span>
                <span>
                  <Link
                    to={"/tdb"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Trulli del Boschetto
                  </Link>
                </span>
              </div>
            </Grid>
            <Grid item xs={4}>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <span className="logoSpan">
                  <h1>Trulli Delia</h1>
                </span>
              </Link>
            </Grid>
            <Grid item xs={4}>
              <div className="sideSpans">
                <span
                  id="prenota"
                  onClick={() => {
                    goTo("footer");
                  }}
                >
                  Prenota Ora
                </span>
              </div>
            </Grid>
          </Grid>
        </Toolbar>

        <Toolbar className="mobile-toolbar">
          <MenuIcon
            style={{ color: "white", fontSize: "2rem" }}
            onClick={() => {
              setMenuOpened(!menuOpened);
            }}
          />
          <span className="logoSpan">
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <h1>Trulli Delia</h1>
            </Link>
          </span>
        </Toolbar>

        {menuOpened && (
          <div className="mobile-menu">
            <span>
              <Link
                to={"/tbv"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  background: "none",
                }}
              >
                Trulli Bella vite
              </Link>
            </span>

            <span>
              <Link
                to={"/tdb"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  background: "none",
                }}
              >
                Trulli Del Boschetto
              </Link>
            </span>

            <span
              id="prenota"
              onClick={() => {
                goTo("footer");
              }}
            >
              Prenota Ora
            </span>
          </div>
        )}
      </AppBar>

      <div className="miniVetrina">
        <img src={currentCover} alt="" />
      </div>

      <Container
        className="pageContainer"
        style={{ padding: "0px 50px", marginTop: "50px" }}
        maxWidth=""
      >
        <Stack spacing={8}>
          <div className="headerSection">
            <h1>Trulli Bella Vite</h1>
            <p>
              Siamo lieti di accogliervi nella nostra struttura immersa nella
              <b> natura</b>, con camere confortevoli e spazi per rilassarsi al
              massimo. La bellezza panoramica dei nostri dintorni vi regalerà
              momenti di <b>pace e tranquillità</b>, mentre il nostro personale
              attento e cortese sarà sempre a vostra disposizione per rendere il
              vostro soggiorno <b>indimenticabile.</b>
            </p>
            <p>
              <b>
                <i>
                  Benvenuti nel vostro rifugio perfetto lontano dallo stress
                  quotidiano!
                </i>
              </b>
            </p>
            <div className="menu">
              <span
                onClick={() => {
                  setMenuSelected(1);
                }}
                style={{
                  background: menuSelected === 1 ? "rgb(255, 243, 226)" : "",
                  color: menuSelected === 1 ? "#364731" : "",
                }}
              >
                Camere
              </span>
              <span
                onClick={() => {
                  setMenuSelected(2);
                }}
                style={{
                  background: menuSelected === 2 ? "rgb(255, 243, 226)" : "",
                  color: menuSelected === 2 ? "#364731" : "",
                }}
              >
                Aree Comuni
              </span>
              <span
                onClick={() => {
                  setMenuSelected(3);
                }}
                style={{
                  background: menuSelected === 3 ? "rgb(255, 243, 226)" : "",
                  color: menuSelected === 3 ? "#364731" : "",
                }}
              >
                i nostri Prodotti
              </span>
            </div>
          </div>

          {(menuSelected === 1 || phoneView) && (
            <div className="camere">
              <Dialog open={dialogOpen !== false} maxWidth="xl">
                {dialogOpen !== false && (
                  <DialogBody
                    dialogOpen={dialogOpen}
                    dialogClose={() => {
                      setDialogOpen(false);
                    }}
                  />
                )}
              </Dialog>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h1>Camere</h1>
                <p>
                  Le nostre camere sono state progettate per offrire il massimo
                  comfort e relax. Sono tutte dotate di bagno privato, aria
                  condizionata, riscaldamento, TV, Wi-Fi gratuito e frigorifero.
                </p>
              </div>

              <div className="camere-list">
                <div className="camera" id="trullo">
                  <div className="descrizione">
                    <h1>Trullo</h1>
                    <h2>fino a 4 ospiti</h2>

                    <i
                      className="fa-solid fa-arrow-right"
                      onClick={() => {
                        setDialogOpen(0);
                      }}
                    ></i>
                  </div>
                </div>

                <div className="camera" id="pietra">
                  <div className="descrizione">
                    <h1>Pietra Viva</h1>
                    <h2> (Appartamento in pietra) fino a 4 ospiti</h2>

                    <i
                      className="fa-solid fa-arrow-right"
                      onClick={() => {
                        setDialogOpen(1);
                      }}
                    ></i>
                  </div>
                </div>

                <div className="camera" id="magnolia">
                  <div className="descrizione">
                    <h1>Magnolia</h1>
                    <h2> (Appartamento) fino a 2 ospiti</h2>

                    <i
                      className="fa-solid fa-arrow-right"
                      onClick={() => {
                        setDialogOpen(2);
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(menuSelected === 2 || phoneView) && (
            <div className="areeComuni">
              <h1>Aree comuni</h1>

              <div className="listaAree camere">
                <div
                  className="camera"
                  style={{ backgroundImage: `url(${piscina})` }}
                >
                  <div className="descrizione">
                    <h1>Piscina al sale</h1>
                    <h2>con ombrelloni, lettini e sdraio</h2>
                  </div>
                </div>
                <div
                  className="camera"
                  style={{ backgroundImage: `url(${gazebo})` }}
                >
                  <div className="descrizione">
                    <h1>Gazebo Esterno</h1>
                    <h2>con tavolo e barbecure</h2>
                  </div>
                </div>
                <div
                  className="camera"
                  style={{ backgroundImage: `url(${cover2})` }}
                >
                  <div className="descrizione">
                    <h1>Parcheggio gratuito</h1>
                    <h2>con 3 posti auto</h2>
                  </div>
                </div>
                <div
                  className="camera"
                  style={{ backgroundImage: `url(${giardino})` }}
                >
                  <div className="descrizione">
                    <h1>Giardino naturale</h1>
                    <h2>
                      in cui trovare una grande varietà di prodotti locali
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(menuSelected === 3 || phoneView) && (
            <div>
              <div className="prodotti">
                <h1>I nostri prodotti</h1>

                <div className="listaProdotti">
                  <div className="embla" ref={emblaRef}>
                    <div className="embla__container">
                      <div className="prodotto embla__slide">
                        <img src="https://media-assets.lacucinaitaliana.it/photos/61fa5b61e126f04c3590ccbb/16:9/w_1280,c_limit/uva-da-vino-e-da-tavola.jpg" />
                        <div className="descrizione">
                          <h1>Uva</h1>
                          <h2>
                            Da qui deriva il nostro nome ‘’Trulli bella vite’’,
                            in struttura troverete varie viti con una vasta
                            scelta di uva, sia bianca che rossa
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://media-assets.lacucinaitaliana.it/photos/629e25b3afffc9d37064038d/16:9/w_2560%2Cc_limit/ciliegie%2520ferrovia.jpg" />
                        <div className="descrizione">
                          <h1>Ciliege</h1>
                          <h2>
                            Frutto delizioso della nostra campagna, pronto ad
                            essere raccolto nel periodo estivo. Nella nostra
                            struttura troverete le Ferrovie, qualità tipica
                            pugliese.
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://www.starbene.it/content/uploads/2020/07/pomodori.jpg" />
                        <div className="descrizione">
                          <h1>Pomodori</h1>
                          <h2>
                            Ortaggio di color rosso perfetto da mangiare su del
                            pane con un filo d’olio
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://static.ohga.it/wp-content/uploads/sites/24/2019/05/olio-tutti-i-tipi.jpg" />
                        <div className="descrizione">
                          <h1>Olio d'oliva</h1>
                          <h2>
                            L’olio che troverete in struttura è interamente di
                            nostra produzione, qualità che troverete da poche
                            altre parti.
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://www.salepepe.it/files/2018/06/Limoni-1140x636.jpg" />
                        <div className="descrizione">
                          <h1>Limoni</h1>
                          <h2>
                            Frutto aspro ma molto incantevole per il suo
                            colorito, adatto ad essere accompagnato a un piatto
                            di pesce crudo.
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://www.naturalbiologico.it/wp-content/uploads/2021/09/mandorle-sgusciate-1-1.jpg" />
                        <div className="descrizione">
                          <h1>Mandorle</h1>
                          <h2>
                            Frutta secca ricca di proprietà benefiche
                            interamente di nostra produzione
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://www.5algiorno.ch/site/assets/files/4477/kaki-1.800x600.jpg" />
                        <div className="descrizione">
                          <h1>Cachi</h1>
                          <h2>
                            Dolcissimi e in grande quantità che potrete trovare
                            nel periodo di ottobre
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://media-assets.lacucinaitaliana.it/photos/61faf86e6a878bf70cf49039/1:1/w_1000,h_1000,c_limit/shutterstock_1183774711.jpg" />
                        <div className="descrizione">
                          <h1>Fichi d'India</h1>
                          <h2>
                            Frutto mediterraneo che consigliamo di assaggiare
                            nel periodo estivo
                          </h2>
                        </div>
                      </div>
                      <div className="prodotto embla__slide">
                        <img src="https://www.sibater.it/wp-content/uploads/2021/02/melograno.jpg" />
                        <div className="descrizione">
                          <h1>Melograni</h1>
                          <h2>
                            Frutto poco calorico e con grandi valori
                            nutrizionali.
                          </h2>
                        </div>
                      </div>
                      <div className="prodotto embla__slide">
                        <img src="https://www.salepepe.it/files/2016/06/albicoccheconsigli-pratici.jpg" />
                        <div className="descrizione">
                          <h1>Albicocche</h1>
                          <h2>
                            Albero da frutta che fa ombra alla soluzione
                            Magnolia
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://www.cure-naturali.it/.imaging/default/dam/cure-naturali/enciclopedia-naturale/alimentazione/fichi.jpg/jcr:content.jpg" />
                        <div className="descrizione">
                          <h1>Fichi</h1>
                          <h2>
                            Frutto molto dolce e piacevole da assaporare, ma
                            attenzione a non mangiarne troppi.
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://www.venditapianteonline.it/wp-content/uploads/2023/05/Menta-Marocchina-.jpg" />
                        <div className="descrizione">
                          <h1>Menta</h1>
                          <h2>
                            Forse la migliore erba aromatica presente in
                            struttura, molto utile da accostare a un insalata di
                            fagiolini.
                          </h2>
                        </div>
                      </div>

                      <div className="prodotto embla__slide">
                        <img src="https://www.centroverderovigo.com/public/blog/original/come-coltivare-il-basilico-1-it-000.jpg" />
                        <div className="descrizione">
                          <h1>Basilico</h1>
                          <h2>
                            Non da utilizzare come medicinale, ma per preparare
                            una pasta alla crodaiola o delle bruschette
                            squisite.
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="divider"></div>
        </Stack>
      </Container>
      <Footer />
    </div>
  );
}
