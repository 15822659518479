import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import TBV from "./pages/TBV";
import TDB from "./pages/TDB";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/tbv",
    element: <TBV />,
  },
  {
    path: "/tdb",
    element: <TDB />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
