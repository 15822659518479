export const listaConsigli = [
  {
    key: 1,
    title: "PUTIGNANO",
    immagine:
      "https://viaggieitineraripuglia.files.wordpress.com/2020/02/carnevale-putignano-carri.jpg",
    description:
      "Città famosa per ospitare il carnevale più antico d’Europa e per le sue grotte carsiche.",
    ristoranti: [
      "Scinuà",
      "Cibaria",
      "Botteghe antiche",
      "Chi va Piano",
      "Premiata Pizzeria",
    ],
  },
  {
    key: 2,
    title: "ALBEROBELLO",
    immagine:
      "https://www.welcometoitalia.com/wp-content/uploads/2021/02/alberobello_shutterstock_1180128679.jpg",
    description:
      "Affascinante paese a 15 minuti in auto dalla nostra struttura, famoso per i suoi tipici trulli, e per il riconoscimento di Patrimonio dell’ UNESCO.",
    ristoranti: ["Il Pinnacolo", "EVO", "Terra Madre", "La Nicchia"],
  },
  {
    key: 3,
    title: "MONOPOLI - CAPITOLO",
    immagine:
      "https://turismo.puglia.it/wp-content/uploads/sites/124/monopoli-hd.jpg",
    description:
      "Cittadina di mare perfetta per i giovani, movida fino a tarda sera e locali eccellenti. Al Capitolo si possono trovare spiagge cristalline premiate bandiera blu",
    ristoranti: ["Porto rosso", "Il Guazzetto", "20MQ", "Avamposto"],
  },
  {
    key: 4,
    title: "POLIGNANO A MARE",
    immagine:
      "https://www.turismovieste.it/index/wp-content/uploads/2023/02/polignano-amre.jpg",
    description:
      "Cittadina con il centro storico che si affaccia sul mare, con le migliori scogliere di tutta la Puglia, premiate bandiera blu. A 30 minuti di auto dalla nostra struttura.",
    ristoranti: ["Pescaria", "Partenope", "Spriz e Polpette", "La Rotellina"],
  },
  {
    key: 5,
    title: "ZOOSAFARI FASANO",
    immagine:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/08/93/d4/0f/zoo-safari-fasano.jpg?w=1200&h=-1&s=1",
    description:
      "Il più grande parco faunistico in Italia e uno dei più grandi d’Europa, con 1700 esemplari di 200 specie diverse. A 25 minuti dalla nostra struttura.",
    ristoranti: [],
  },
  {
    key: 6,
    title: "GROTTE DI CASTELLANA",
    immagine:
      "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/timo-volz-b587f4cdhgw-unsplash-1584964641.jpg",
    description:
      "Il più grande parco faunistico in Italia e uno dei più grandi d’Europa, con 1700 esemplari di 200 specie diverse. A 25 minuti dalla nostra struttura.",
    ristoranti: [],
  },
  {
    key: 7,
    title: "MATERA",
    immagine:
      "https://i.guim.co.uk/img/media/65e26cfd2440bfe4f331e163a2cde1b20e8bc5de/0_337_5760_3456/master/5760.jpg?width=1200&quality=85&auto=format&fit=max&s=e083b49425dda43ef601f9dc7184877e",
    description:
      "Matera situata in della Basilicata, è famosa per i suoi Sassi, con cui sono state costruite un complesso di antiche abitazioni scavate nella roccia, che costituiscono uno dei più antichi insediamenti umani continuativi al mondo",
    ristoranti: ["La Lopa"],
  },
  {
    key: 8,
    title: "OSTUNI",
    immagine:
      "https://turismo.puglia.it/wp-content/uploads/sites/124/ostuni-panorama-hd.jpg",
    description:
      'Ostuni, conosciuta anche come la "Città Bianca", è un\'affascinante città situata nella regione italiana della Puglia. Caratterizzata dalle sue casette bianche e stradine tortuose, offre un suggestivo centro storico medievale e una vista panoramica mozzafiato sulle campagne circostanti e sul mare Adriatico.',
    ristoranti: ["Taverna della Gelosia", "Osteria Piazzetta Cattedrale"],
  },
  {
    key: 9,
    title: "BARI",
    immagine:
      "https://www.itabus.it/on/demandware.static/-/Sites-ITABUS-Library/default/dw59de0bfa/Destinazioni/Puglia/Bari/Aerial-panoramic-view-above-Bari.jpg",
    description:
      "Bari è una città portuale sulla costa adriatica, con un affascinante centro storico con la Basilica di San Nicola, inoltre offre una combinazione di cultura, gastronomia e atmosfera costiera che la rendono unica.",
    ristoranti: ["Mastro Ciccio", "La cantina dello zio"],
  },
];
