import React from "react";
import { listaConsigli } from "./consigli-list";
import "../styles/consigli-slider.css";
import Place from "./place";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

export default function ConsigliSlider() {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [
    Autoplay({ delay: 5000 }),
  ]);

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        {listaConsigli.map((consiglio) => (
          <div className="embla___slide" key={consiglio.key}>
            <Place
              immagine={consiglio.immagine}
              title={consiglio.title}
              description={consiglio.description}
              ristoranti={consiglio.ristoranti}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
