import React from "react";
import "../styles/place.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";

export default function Place(props) {
  return (
    <div className="place-card">
      <img src={props.immagine} alt="" />
      <div className="description">
        <h2>{props.title}</h2>
        <p>{props.description}</p>
        <div className="ristoranti">
          {props.ristoranti.map((ristorante, i) => {
            return (
              <div className="chip" key={i}>
                {ristorante}{" "}
                <FontAwesomeIcon
                  icon={faUtensils}
                  style={{ margin: "0px 5px" }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
