import React, { useEffect } from "react";
import "../styles/dialog-camere.scss";
import { Icon } from "@mui/material";
import $ from "jquery";
import { useState } from "react";

let imgs = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
};

export default function DialogBody({ dialogClose, dialogOpen }) {
  const [imgIndex, setImgIndex] = useState(0);
  const [showImages, setShowImages] = useState(false);

  // check if esc key is pressed
  $(document).keydown(function (e) {
    if (e.key === "Escape") {
      dialogClose();
    }
  });

  // check if i click outside the box
  $(document).mouseup(function (e) {
    var container = $(".dialog-container");
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      dialogClose();
    }
  });

  // go next img
  $(".arrow-right").click(function () {
    if (imgIndex < camere[dialogOpen].imgs.length - 1) {
      setImgIndex(imgIndex + 1);
    } else {
      setImgIndex(0);
    }
  });

  // go prev img
  $(".arrow-left").click(function () {
    if (imgIndex > 0) {
      setImgIndex(imgIndex - 1);
    } else {
      setImgIndex(camere[dialogOpen].imgs.length - 1);
    }
  });

  useEffect(() => {
    for (let i = 1; i <= camere[dialogOpen].maxImg; i++) {
      // Usa require per importare dinamicamente le immagini
      const immagine = require(`../images/${camere[dialogOpen].imgPath}/${i}.jpg`);
      imgs[dialogOpen].push(immagine);
    }

    // show images after they are loaded
    setTimeout(() => {
      setShowImages(true);
    }, 2000);
  }, []);

  return (
    <div className="dialog-container">
      <Icon
        className="fa-solid fa-times close-icon"
        onClick={dialogClose}
      ></Icon>
      <div className="dialog-content">
        <div className="header">
          <h1>{camere[dialogOpen].title}</h1>
          <p>{camere[dialogOpen].descr}</p>
        </div>

        <div className="camera-info">
          <div className="imgs-section">
            <Icon className="fa-solid fa-chevron-left arrow-left"></Icon>
            <Icon className="fa-solid fa-chevron-right arrow-right"></Icon>
            {
              // show images after they are loaded
              showImages ? (
                <img src={camere[dialogOpen].imgs[imgIndex]} />
              ) : (
                <div className="loading">
                  <span className="loader"></span>
                </div>
              )
            }
          </div>
          <div className="chips-section">
            {camere[dialogOpen].chips.map((chip, index) => {
              return (
                <div key={index} className="chip">
                  <Icon className="fa-solid fa-check chip-icon"></Icon>
                  <p>{chip}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

// camere data
const camere = [
  {
    title: "Trullo",
    descr:
      "Soluzione per un massimo di 4 ospiti composta da cucina in cui è posto un divano letto, bagno e camera matrimoniale.",
    chips: [
      "Aria condizionata",
      "Televisore",
      "Pentolame",
      "Frigorifero e congelatore",
      "Biancheria",
      "Materiale per la prima colazione",
      "Carta igienica",
      "Tris di asciugamani per ogni ospite",
      "Asciugacapelli",
      "Armadio",
    ],
    imgs: imgs[0],
    imgPath: "tbv orizzontale/Trullo",
    maxImg: 18,
  },
  {
    title: "Pietra Viva",
    descr:
      "Soluzione per un massimo di 4 ospiti composta da cucina, bagno, e camera matrimoniale dove sono presenti due lettini singoli.",
    chips: [
      "Aria condizionata",
      "Televisore",
      "Pentolame",
      "Frigorifero e congelatore",
      "Biancheria",
      "Materiale per la prima colazione",
      "Carta igienica",
      "Tris di asciugamani per ogni ospite",
      "Asciugacapelli",
      "Armadio",
    ],
    imgs: imgs[1],
    imgPath: "tbv orizzontale/Pietra viva",
    maxImg: 16,
  },
  {
    title: "Magnolia",
    descr:
      "Soluzione per un massimo di 2 ospiti composta da cucina, bagno, e camera matrimoniale.",
    chips: [
      "Aria condizionata",
      "Televisore",
      "Pentolame",
      "Frigorifero e congelatore",
      "Biancheria",
      "Materiale per la prima colazione",
      "Carta igienica",
      "Tris di asciugamani per ogni ospite",
      "Asciugacapelli",
      "Armadio",
    ],
    imgs: imgs[2],
    imgPath: "tbv orizzontale/Magnolia",
    maxImg: 16,
  },
  {
    title: "Trullo",
    descr:
      "Soluzione per un massimo di 4 ospiti composta da cucina, camera matrimoniale, camera con due letti singoli e bagno.",
    chips: [
      "Aria condizionata",
      "Televisore",
      "Pentolame",
      "Frigorifero e congelatore",
      "Biancheria",
      "Materiale per la prima colazione",
      "Carta igienica",
      "Tris di asciugamani per ogni ospite",
      "Asciugacapelli",
      "Armadio",
    ],
    imgs: imgs[3],
    imgPath: "tdb orizzontale/Trullo",
    maxImg: 23,
  },
  {
    title: "Trulletto",
    descr:
      "Soluzione per un massimo di 2 ospiti composta da camera matrimoniale e un bagno.",
    chips: [
      "Aria condizionata",
      "Televisore",
      "Pentolame",
      "Frigorifero e congelatore",
      "Biancheria",
      "Materiale per la prima colazione",
      "Carta igienica",
      "Tris di asciugamani per ogni ospite",
      "Asciugacapelli",
      "Armadio",
    ],
    imgs: imgs[4],
    imgPath: "tdb orizzontale/Trulletto",
    maxImg: 15,
  },
  {
    title: "Girasole",
    descr:
      "Soluzione per un massimo di 3 ospiti composta da cucina, camera matrimoniale con un letto singolo e un  bagno.",
    chips: [
      "Aria condizionata",
      "Televisore",
      "Pentolame",
      "Frigorifero e congelatore",
      "Biancheria",
      "Materiale per la prima colazione",
      "Carta igienica",
      "Tris di asciugamani per ogni ospite",
      "Asciugacapelli",
      "Armadio",
    ],
    imgs: imgs[5],
    imgPath: "tdb orizzontale/Girasole",
    maxImg: 15,
  },
];
